import React from 'react';
import styled from "styled-components";
import "../index.css"
import Header from "../components/header";
import Footer from "../components/footer";
import MapImage from "../images/map.svg"
import Decoration from "../images/decoration.svg";
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/seo";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 1110px;
  align-self: center;
  position: relative;
  @media screen and (max-width: 1100px) {
    width: 1000px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 800;
  font-size: 30px;
  color: #4C77FF;
  margin-bottom: 46px;
  align-self: center;
  width: 1110px;
  @media screen and (max-width: 1100px) {
    width: 1000px;
    font-size: 26px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
    font-size: 23px;
    margin-bottom: 33px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
    font-size: 20px;
    margin-bottom: 26px;
  }
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  line-height: 23px;
  color: #212939;
  text-align: left;
  white-space: break-spaces;
  margin-bottom: 28px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const Map = styled.img`
  margin-bottom: 155px;
  width: 1050px;
  @media screen and (max-width: 1100px) {
    width: 950px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
  }
`;

const Geography = () => {

    const ReturnImages = () => {
        return (
            <>
                <Image src={Decoration} alt="decor"/>
                <Image2 src={Decoration} alt="decor"/>
            </>
        )
    }

    const Image = styled.img`
      position: absolute;
      left: -460px;
      top: 60px;
      z-index: -1;
      @media screen and (max-width: 800px) {
        display: none;
      }
    `;

    const Image2 = styled.img`
      position: absolute;
      left: 1230px;
      top: 720px;
      z-index: -1;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      @media screen and (max-width: 1280px) {
        display: none;
      }
    `;

    const data = useStaticQuery(query);

    return (
            <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <Seo title={data.allStrapiGeographySeos.nodes[0].seo_title}
                     description={data.allStrapiGeographySeos.nodes[0].seo_description}
                     keywords={data.allStrapiGeographySeos.nodes[0].seo_keywords.split(',')}
                />
                <MainContainer>
                    <div style={{alignSelf: "center"}}>
                        <Header/>
                    </div>
                    <Container>
                        <ReturnImages/>
                        <Title>
                            География деятельности
                        </Title>
                        <Text>
                            География деятельности компании охватывает всю территорию Российской Федерации, начиная
                            Центральным регионом,
                            заканчивая Дальним Востоком.
                        </Text>
                        <Map src={MapImage} alt="map"/>
                    </Container>
                </MainContainer>
                <Footer/>
            </div>
    )
}

const query = graphql`
query{
  allStrapiGeographySeos {
    nodes {
      seo_title
      seo_decription
      seo_keywords
    }
  }
}
`;

export default Geography;